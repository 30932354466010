import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { withPage } from '../Page';
import ActionCreator from '../ActionCreator';
import Selectors from '../Selectors';
import * as Widget from '../Components/Widget';
import OrderDetailContent from '../Components/OrderDetailContent';

class OrderDetailPage extends React.Component {
  render() {
    let { location, profile, order } = this.props;

    if (!profile) {
      return null;
    }

    return (
      <Wrapper>
        <Widget.MaxWidth width={800}>
          <OrderDetailContent
            key={location.search}
            location={location}
            order={order}
          />
        </Widget.MaxWidth>
      </Wrapper>
    );
  }
}

let Wrapper = styled.div`
  padding: 20px;
`;

export default withPage(
  connect(
    (state, ownProps) => ({
      profile: Selectors.getLoginUser(state),
      order: Selectors.getOrder(state, Selectors.getQueryParams(ownProps).id),
    }),
    ActionCreator
  )(OrderDetailPage)
);
