import React from 'react';
import { connect } from 'react-redux';
import ActionCreator from '../ActionCreator';
import ParseQuery from '../Utils/ParseQuery';
import * as L from '../Utils/Lang';
import * as Widget from './Widget';
import OrderItem from './OrderItem';

class OrderDetailContent extends React.Component {
  state = {
    isFetching: false,
    fetchErrMsg: '',
  };

  componentDidMount() {
    let orderId = ParseQuery(this.props.location.search).id;

    if (orderId) {
      let { appActions } = this.props;

      this.setState({ isFetching: true, fetchErrMsg: '' });
      appActions
        .getOrder(orderId)
        .then(() => this.setState({ isFetching: false }))
        .catch(() => {
          this.setState({
            fetchErrMsg: L.s('no-order-or-no-right-access-order'),
            isFetching: false,
          });
        });
    }
  }

  render() {
    let { extraCss, order } = this.props;
    let { isFetching, fetchErrMsg } = this.state;

    if (isFetching) {
      return (
        <Widget.Center>
          <Widget.Spinner />
        </Widget.Center>
      );
    }

    if (!order) {
      return <Widget.Center>{fetchErrMsg || ''}</Widget.Center>;
    }

    return <OrderItem extraCss={extraCss} detail order={order} />;
  }
}

export default connect(
  null,
  ActionCreator
)(OrderDetailContent);
